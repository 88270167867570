import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Home, 
  Gauge, 
  BarChart2,
  MessageSquare,
  LogOut,
  Menu,
} from 'lucide-react';
import Cookies from 'js-cookie';
import axios from 'axios';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navItems = [
    { icon: Home, label: 'Dashboard', path: '/dashboard' },
    { icon: Gauge, label: 'Overview', path: '/dashboard' },
    { icon: BarChart2, label: 'Statistics', path: '/statistics' },
    { icon: MessageSquare, label: 'Chat', path: '/chat' },
  ];

  const handleLogout = () => {
    // Try multiple approaches to ensure cookies are deleted
    const cookieNames = ['sessiontoken', 'dot'];
    const domains = ['.brokerwatch.co', 'brokerwatch.co', window.location.hostname];
    const paths = ['/', '/dashboard', ''];
  
    // Try all combinations of domains and paths
    cookieNames.forEach(cookieName => {
      // First try without domain
      paths.forEach(path => {
        Cookies.remove(cookieName, { path });
      });
  
      // Then try with each domain
      domains.forEach(domain => {
        paths.forEach(path => {
          Cookies.remove(cookieName, { 
            domain,
            path
          });
        });
      });
  
      // Also try setting to expired date
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    });
  
    // Log remaining cookies
    console.log('Remaining cookies:', document.cookie);
  
    // Navigate away
    navigate('/', { replace: true });
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="md:hidden bg-white p-4 flex justify-between items-center w-full">
        <div className="bg-blue-600 text-white font-bold text-2xl w-10 h-10 flex items-center justify-center rounded">
          B
        </div>
        <button 
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} 
          className="text-gray-600"
        >
          <Menu size={24} />
        </button>
      </div>

      {/* Sidebar Container */}
      <div className={`
        bg-white md:w-16 md:min-h-screen flex flex-col py-4
        fixed md:relative transition-all duration-300
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} 
        md:translate-x-0 md:block
        h-full z-50
      `}>
        {/* Logo */}
        <div className="flex justify-center w-full">
          <div className="bg-blue-600 text-white font-bold text-2xl w-12 h-12 flex items-center justify-center rounded mb-8">
            B
          </div>
        </div>
        
        {/* Navigation Items */}
        <nav className="flex flex-col items-center space-y-6 w-full">
          {navItems.map((item) => (
            <button
              key={item.path + item.label}
              onClick={() => handleNavigation(item.path)}
              className={`
                w-full p-3 flex justify-center
                transition-all duration-200 group relative
                ${location.pathname === item.path 
                  ? 'bg-blue-600' 
                  : 'hover:bg-blue-600'}
              `}
            >
              <item.icon className="w-6 h-6 text-white group-hover:scale-110 transition-transform duration-200" />
            </button>
          ))}
        </nav>

        {/* Spacer between nav items and logout */}
        <div className="flex-grow"></div>

        {/* Logout Button */}
        <button 
          onClick={handleLogout}
          className="
            hidden md:flex w-full p-3 justify-center mt-6
            hover:bg-red-500 transition-colors duration-200 
            group relative
          "
        >
          <LogOut className="w-6 h-6 text-white group-hover:scale-110 transition-transform duration-200" />
        </button>
      </div>

      {/* Mobile Overlay */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;