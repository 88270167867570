import React, { useState } from 'react';
import { Send, MoreVertical, Search } from 'lucide-react';

interface Message {
  id: number;
  user: string;
  content: string;
  timestamp: string;
  isMe: boolean;
}

const Chat = () => {
  const [newMessage, setNewMessage] = useState('');
  const [messages] = useState<Message[]>([
    {
      id: 1,
      user: "John's Trucking",
      content: "Hey, just wondering about load #45678. The detention time wasn't recorded correctly.",
      timestamp: "10:30 AM",
      isMe: false
    },
    {
      id: 2,
      user: "BrokerCo Support",
      content: "I'll look into that right away. Can you provide the date of service?",
      timestamp: "10:32 AM",
      isMe: true
    },
    {
      id: 3,
      user: "John's Trucking",
      content: "It was last Tuesday, March 15th. We waited for 3 hours but only 1 was recorded.",
      timestamp: "10:33 AM",
      isMe: false
    },
    {
      id: 4,
      user: "BrokerCo Support",
      content: "I see the discrepancy. I'll update the record to reflect the full 3 hours of detention time. The adjustment will appear in your next settlement.",
      timestamp: "10:35 AM",
      isMe: true
    }
  ]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setNewMessage('');
    }
  };

  return (
    <div className="flex h-screen">
      {/* Chat List Sidebar */}
      <div className="hidden md:flex flex-col w-80 bg-blue-600">
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold text-white">Messages</h2>
            <button className="text-white hover:text-blue-100">
              <MoreVertical size={20} />
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search conversations..."
              className="w-full pl-10 pr-4 py-2 bg-blue-500 text-white placeholder-blue-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <Search className="absolute left-3 top-2.5 text-blue-200" size={20} />
          </div>
        </div>

        {/* Recent Conversations */}
        <div className="overflow-y-auto flex-1">
          {['Active Load Support', 'Billing Department', 'Dispatch Team', 'General Support'].map((chat, index) => (
            <button
              key={index}
              className={`w-full p-4 flex items-center gap-3 border-b border-blue-500
                ${index === 0 ? 'bg-blue-500' : 'hover:bg-blue-500'}
              `}
            >
              <div className="bg-white text-blue-600 w-10 h-10 rounded-full flex items-center justify-center font-bold">
                {chat.charAt(0)}
              </div>
              <div className="flex-1 text-left">
                <h3 className="font-semibold text-white">{chat}</h3>
                <p className="text-sm text-blue-200">Latest message preview...</p>
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Chat Main Area */}
      <div className="flex-1 flex flex-col bg-gray-50">
        {/* Chat Header */}
        <div className="bg-white p-4 border-b flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="bg-blue-600 text-white w-10 h-10 rounded-full flex items-center justify-center font-bold">
              A
            </div>
            <div>
              <h2 className="font-bold">Active Load Support</h2>
              <p className="text-sm text-blue-600">Online</p>
            </div>
          </div>
          <button className="text-blue-600">
            <MoreVertical size={20} />
          </button>
        </div>

        {/* Messages Area */}
        <div className="flex-1 overflow-y-auto p-4">
          <div className="space-y-4">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${message.isMe ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`
                  max-w-[70%] rounded-lg p-3
                  ${message.isMe 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-white shadow-sm border border-gray-100'}
                `}>
                  {!message.isMe && (
                    <p className="text-sm font-semibold text-blue-600 mb-1">{message.user}</p>
                  )}
                  <p className={message.isMe ? 'text-white' : 'text-gray-900'}>{message.content}</p>
                  <p className={`text-xs mt-1 ${message.isMe ? 'text-blue-100' : 'text-blue-600'}`}>
                    {message.timestamp}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Message Input */}
        <div className="bg-white border-t p-4">
          <div className="flex gap-4">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:border-blue-600"
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;