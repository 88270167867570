import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar.tsx';

const AppLayout = () => {
  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-4 md:p-6 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;