import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Truck, DollarSign, Clock, AlertTriangle } from 'lucide-react';

const Statistics = () => {
  // Dummy data for demonstration
  const monthlyData = [
    { month: 'Jan', loads: 145, revenue: 245000, rating: 4.8 },
    { month: 'Feb', loads: 132, revenue: 223000, rating: 4.7 },
    { month: 'Mar', loads: 151, revenue: 267000, rating: 4.9 },
    { month: 'Apr', loads: 128, revenue: 218000, rating: 4.6 },
    { month: 'May', loads: 143, revenue: 241000, rating: 4.8 },
    { month: 'Jun', loads: 159, revenue: 278000, rating: 4.9 },
  ];

  const pieData = [
    { name: 'On Time', value: 85 },
    { name: 'Late', value: 10 },
    { name: 'Issues', value: 5 },
  ];

  const COLORS = ['#3B82F6', '#60A5FA', '#93C5FD'];

  return (
    <div className="p-4 md:p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Broker Performance Statistics</h1>
        <div className="flex gap-2">
          <select className="border rounded-md p-2">
            <option>Last 6 Months</option>
            <option>Last Year</option>
            <option>All Time</option>
          </select>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-500 text-white p-4 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm opacity-90">Total Loads</p>
              <h3 className="text-3xl font-bold">858</h3>
            </div>
            <Truck className="w-8 h-8" />
          </div>
          <p className="text-sm mt-2">+12% from last period</p>
        </div>

        <div className="bg-blue-500 text-white p-4 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm opacity-90">Average Revenue</p>
              <h3 className="text-3xl font-bold">$245K</h3>
            </div>
            <DollarSign className="w-8 h-8" />
          </div>
          <p className="text-sm mt-2">+8% from last period</p>
        </div>

        <div className="bg-blue-500 text-white p-4 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm opacity-90">Average Load Time</p>
              <h3 className="text-3xl font-bold">2.3 Days</h3>
            </div>
            <Clock className="w-8 h-8" />
          </div>
          <p className="text-sm mt-2">-5% from last period</p>
        </div>

        <div className="bg-blue-500 text-white p-4 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm opacity-90">Issue Rate</p>
              <h3 className="text-3xl font-bold">3.2%</h3>
            </div>
            <AlertTriangle className="w-8 h-8" />
          </div>
          <p className="text-sm mt-2">-2% from last period</p>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Monthly Performance */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Monthly Performance</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={monthlyData}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="loads" fill="#3B82F6" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Load Status Distribution */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Load Status Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                innerRadius={60}
                outerRadius={100}
                paddingAngle={5}
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="flex justify-center gap-4 mt-4">
            {pieData.map((entry, index) => (
              <div key={entry.name} className="flex items-center">
                <div 
                  className="w-3 h-3 rounded-full mr-2" 
                  style={{ backgroundColor: COLORS[index] }} 
                />
                <span className="text-sm">{entry.name} ({entry.value}%)</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Detailed Stats Table */}
      <div className="mt-6 bg-white rounded-lg shadow overflow-hidden">
        <h2 className="text-lg font-semibold p-4 border-b">Monthly Breakdown</h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Loads</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Revenue</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {monthlyData.map((month) => (
                <tr key={month.month}>
                  <td className="px-6 py-4 whitespace-nowrap">{month.month}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{month.loads}</td>
                  <td className="px-6 py-4 whitespace-nowrap">${month.revenue.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{month.rating}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Statistics;